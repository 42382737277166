<template>

  <b-card>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive"    
      :dataSilder="sidebarData"   
      @refreshData="refreshData"
      :pageTitle="pageTitle"
      :parentCategories="items"
    />

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >


     <b-button
        @click="addNewData"
        variant="outline-primary"
        class="mb-2"
      >
        Add {{pageTitle}}
      </b-button>

      <b-table responsive :items="items" :fields="tableColumns">

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>

      <template #cell(categories_image)="data">
        <b-avatar :src="data.value" />
      </template>

      


      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.index)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(created_by)="data">
        {{data.value.name}} 
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>


      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <child-table 
          :childs="row.item.all_children" v-on:deleteData="deleteData" v-on:editData="editData" />
      </template>



      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editData(data.item)" >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteData(data.item.id)" > 
              <feather-icon icon="TrashIcon" />
              <span  class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

    </b-overlay>
  </b-card>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton, BFormCheckbox, BRow, BCol, } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
import DataServices from './data/services'
import ChildTable from './child.vue'
export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    ToastificationContent,
    ChildTable,
    BFormCheckbox, 
    BRow, 
    BCol,
  },
  data() {
    return {
      isLoading: true,
      isAddNewSidebarActive: false,
      sidebarData: {},
      tableColumns: ['id','name', 'name_ar','type','brand_image', 'brand_icon', 'status', 'created_by', 'actions', ],
      tableColumns: [
        { key: 'show_details', label: 'SubCategory'},
        { key: 'id', sortable: true },
        { key: 'name', label: ' Name', sortable: true },
        { key: 'name_ar', label: 'Arabic Name', sortable: true },
        { key: 'machine_type.description', label: 'Type', sortable: true },
        { key: 'categories_image', label: 'Image', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created_by', label: 'Created by', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        { key: 'actions' },
      ],
      items: [],
      pageTitle: 'Catogeries',
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
      
    }
  },
  methods: {
    getData(){
      this.isLoading = true;
        DataServices.getAll()
        .then(response => {
          this.isLoading = false
          this.items = response.data.data

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'successfully',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'success',
              },
            })
       
          
        })
    },
    deleteData(id){
      this.isLoading = true;
      DataServices.delete(id)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    changeStatus(id, index){
      this.isLoading = true;
      DataServices.changeStatus(id)
      .then(response => {
        this.isLoading = false
        if(this.items[index].status == 1){
          this.items[index].status = 0
        }else{
          this.items[index].status = 1
        }
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      
        
      })
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    },
  },
  mounted() {
    this.getData()
  },
  
}
</script>