import axios from '@/libs/axios'

class DataService {
    getAll() {
        return axios.get("/category");
    }
    getCompanyType() {
        return axios.get('/companyType')
    }
    get(id) {
        return axios.get(`/category/${id}`);
    }
    getMachineType() {
        return axios.get(`/machineTypeLkb`);
    }
    create(data) {
        return axios.post("/category", data);
    }
    update(id, data) {
        return axios.post(`/category/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/category/${id}`);
    }
    deleteAll() {
        return axios.delete(`/category`);
    }
    findByTitle(title) {
        return axios.get(`/category?title=${title}`);
    }
    changeStatus(id) {
        return axios.get(`/changeCategoryStatus/${id}`)
    }
}
export default new DataService();