<template>
      <div>
            <b-table responsive="sm" :items="childs" :fields="tableColumns">
                  <template #cell(id)="data">
                        {{data.index + 1}}
                  </template>

                  <template #cell(categories_image)="data">
                        <b-avatar :src="data.value" />
                  </template>

                  <template #cell(categories_icon)="data">
                        <b-avatar :src="data.value" />
                  </template>


                  <template #cell(status)="data">
                        <b-badge @click="changeStatus(data.item.id, data.index)" :variant="status[1][data.value]" style="cursor: pointer;">
                              {{ status[0][data.value] }}
                        </b-badge>
                  </template>

                  <template #cell(created_by)="data">
                        {{data.value.name}} 
                  </template>

                  <template #cell(created_at)="data" >
                  {{data.value | moment('YY-MM-DD HH:mm:ss')}}
                  </template>


            <!-- Column: Actions -->
            <template #cell(actions)="data">
            <b-dropdown
                  variant="link"
                  no-caret
                  
            >

                  <template #button-content>
                  <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                  />
                  </template>
                  <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Details</span>
                  </b-dropdown-item> -->

                  <b-dropdown-item @click="editData(data.item)" >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteData(data.item.id)" > 
                  <feather-icon icon="TrashIcon" />
                  <span  class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
            </b-dropdown>
            </template>
        </b-table>
      </div>
</template>

<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton, BFormCheckbox, BRow, BCol, } from 'bootstrap-vue'
import DataServices from './data/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
      components: {
            ToastificationContent
      },
      props: {
            childs: {
                  type: [Array, Object],
                  default: () => {},
            },
            // changeStatus: {
            //       type: Function,
            // }
      },
      data() {
            return {
                  tableColumns: [
                        { key: 'id', sortable: true },
                        { key: 'name', label: ' Name', sortable: true },
                        { key: 'name_ar', label: 'Arabic Name', sortable: true },
                        { key: 'type', label: 'Type', sortable: true },
                        { key: 'categories_image', label: 'Image', sortable: true },
                        { key: 'categories_icon', label: 'Icon', sortable: true },
                        { key: 'status', label: 'Status', sortable: true },
                        { key: 'created_by', label: 'Created by', sortable: true },
                        { key: 'created_at', label: 'Created At', sortable: true },
                        { key: 'actions' },
                  ],
                  status: [
                        {
                              1: 'Actvie', 0: 'Inactive',
                        },
                        {
                              1: 'light-success', 0: 'light-danger', 
                        }
                  ],
            }
      },
      components: {
            BTable,
            BCard,
            BCardText,
            BAvatar,
            BOverlay,
            BBadge,
            BFormSelect,
            BDropdownItem,
            BDropdown,
            BButton,
            BFormCheckbox,
            BRow, 
            BCol,
      },
      methods: {
            changeStatus(id, index){
                  this.isLoading = true;
                  DataServices.changeStatus(id)
                  .then(response => {
                  this.isLoading = false
                  if(this.childs[index].status == 1){
                  this.childs[index].status = 0
                  }else{
                  this.childs[index].status = 1
                  }
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'successfully',
                        icon: 'BellIcon',
                        text: response.data.responseDescription,
                        variant: 'success',
                        },
                  })
                  
                  
                  })
            },
            deleteData(id){
                  console.log('amro ');
                  this.$emit('deleteData', id)
            },
            editData(data){
                  this.$emit('editData', data)
            }
            
      }
}
</script>
